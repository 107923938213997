import React from "react";

import { render } from "react-snapshot";

import { BrowserRouter } from "react-router-dom";
import App from "./App.js";
import * as serviceWorker from "./serviceWorker";

import "./styles.css";

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.register();