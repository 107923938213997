import React, { Component } from "react";

import Imghero_1920 from "../images/resized/1920/10.jpg";
import Imghero_1800 from "../images/resized/1800/10.jpg";
import Imghero_1200 from "../images/resized/1200/10.jpg";
import Imghero_900 from "../images/resized/900/10.jpg";
import Imghero_600 from "../images/resized/600/10.jpg";


class Home extends Component {
  render() {
    return (
      <div  className="heroimage">
        <img className="heroimageimg" alt="Brahma Psychadelic" src={Imghero_1920} srcSet={`${Imghero_1800} 1800w, ${Imghero_1200} 1200w, ${Imghero_900} 900w, ${Imghero_600} 600w`} />
      </div>

    );
  }
}

export default Home;