import React, { Component } from "react";
import Imgfacebook from "../images/fb2.png";
import Imgtwitter from "../images/twitter2.png";
import Imginstagram from "../images/instagram2.png";
import Imglinkedin from "../images/li2.png";

class Footer extends Component {
  render() {
    return (
        <div class="footer">
            <div className="copyright"><a>Copyright BRAHMAFORCES @2019</a></div>
            <div className="phonefooter"><a>+919810067743</a></div> 
        
            <div className="socialmediaiconsfooter">
                <div className="facebookfooter"><a href="http://www.facebook.com/brahmaforces" target="_blank"><img src={Imgfacebook}  alt="Facebook" /></a></div>
                <div className="instagramfooter"><a href="http://www.instagram.com/brahmaforces" target="_blank"><img src={Imginstagram} alt="Instagram" /></a></div>
                <div className="twitterfooter"><a href="http://www.twitter.com/brahmaforces" target="_blank"><img src={Imgtwitter} alt="Twitter" /></a></div>
                <div className="linkedinfooter"><a href="http://www.linkedin.com/in/brahmaforces" target="_blank"><img src={Imglinkedin} alt="Linkedin" /></a></div>
            </div>
            <div className="brahmaforces"><a>Website by Brahmaforces</a></div>
        </div>
    );
  }
}

export default Footer;




    