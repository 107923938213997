import React, { Component } from "react";
import { Link } from "react-router-dom";

class Topnav extends Component {
  render() {
    return (
    <div class="topnav">
        <Link to="/">HOME</Link>
        <Link to="/about">ABOUT</Link>
        <Link to="/web">WEB</Link>
        <Link to="/art">ART</Link>
        <Link to="/books">BOOKS</Link>
        <Link to="/portfolio">PORTFOLIO</Link>
        <a href="https://medium.com/@arjunsinghkochhar" target="_blank">BLOG</a>
        <Link to="/contact">CONTACT</Link>
    </div>

    );
  }
}

export default Topnav;



