import React, { Component } from "react";

import ImgAbout_1920 from "../images/resized/1920/1.jpg";
import ImgAbout_1800 from "../images/resized/1800/1.jpg";
import ImgAbout_1200 from "../images/resized/1200/1.jpg";
import ImgAbout_900 from "../images/resized/900/1.jpg";
import ImgAbout_600 from "../images/resized/600/1.jpg";



class About extends Component {
  render() {
    return (
      <div className="pagecontainer">
          <div className="pagetitle">
              <h2>ABOUT</h2>
          </div>
    
          <div  className="heroimage">
            <img alt="Brahmaforces About" className="heroimageimg" src={ImgAbout_1920} srcset={`${ImgAbout_600} 600w, ${ImgAbout_900} 900w, ${ImgAbout_1200} 1200w, ${ImgAbout_1800} 1800w, ${ImgAbout_1920} 1920w`}  />
        </div>

          <div className="pagetext">
              <p>Brahma’s core focus is to develop beautiful, fast and cutting edge mobile first websites. </p>
              <p>Brahmaforces is a small ultra creative full stack media company.</p>
              <p>Brahma combines Software and Art to create in the realms of the Internet, Graphics and Print. </p>
          </div>
      </div>
    );
  }
}

export default About;