import React, { Component } from "react";

import ImgContact_1920 from "../images/resized/1920/2.jpg";
import ImgContact_1800 from "../images/resized/1800/2.jpg";
import ImgContact_1200 from "../images/resized/1200/2.jpg";
import ImgContact_900 from "../images/resized/900/2.jpg";
import ImgContact_600 from "../images/resized/600/2.jpg";

class Contact extends Component {
  render() {
    return (
      <div className="pagecontainer">
          <div className="pagetitle">
            <h2>CONTACT</h2>
          </div>
    
          <div  className="heroimage">
            <img className="heroimageimg" src={ImgContact_1920} srcset={`${ImgContact_600} 600w, ${ImgContact_900} 900w, ${ImgContact_1200} 1200w, ${ImgContact_1800} 1800w, ${ImgContact_1920} 1920w`} alt="" />
        </div>

          <div className="pagetext">
         
          <p>brahmaforces@gmail.com</p>
          <p>9810067743</p>
          </div>
      </div>
    );
  }
}

export default Contact;




