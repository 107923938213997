import React, { Component } from "react";

import Imglandscape1_1920 from "../images/landscape/1920/1_1920.jpg";
import Imglandscape1_1800 from "../images/landscape/1800/1_1800.jpg";
import Imglandscape1_1200 from "../images/landscape/1200/1_1200.jpg";
import Imglandscape1_900 from "../images/landscape/900/1_900.jpg";
import Imglandscape1_600 from "../images/landscape/600/1_600.jpg";

import Portfolio1 from "../images/portfolio/supersamsara.jpg";
import Portfolio1mob from "../images/portfolio/supersamsaramob.jpg";

import Portfolio2 from "../images/portfolio/fivelement.jpg";
import Portfolio2mob from "../images/portfolio/fivelementmob.jpg";

import Portfolio3 from "../images/portfolio/geetanjalipandit.jpg";
import Portfolio3mob from "../images/portfolio/geetanjalipanditmob.jpg";

import Portfolio4 from "../images/portfolio/helmut.jpg";
import Portfolio4mob from "../images/portfolio/helmutmob.jpg";

import Portfolio5 from "../images/portfolio/kareri.jpg";
import Portfolio5mob from "../images/portfolio/karerimob.jpg";

import Portfolio6 from "../images/portfolio/mohanshyam.jpg";
import Portfolio6mob from "../images/portfolio/mohanshyammob.jpg";

import Portfolio7 from "../images/portfolio/noidadeafsociety.jpg";
import Portfolio7mob from "../images/portfolio/noidadeafsocietymob.jpg";

import Portfolio8 from "../images/portfolio/zenmoksha.jpg";
import Portfolio8mob from "../images/portfolio/zenmokshamob.jpg";

class Portfolio extends Component {
  constructor() {
    super();
    this.state= {arrPortfolio: [
      {
          domain: 'https://supersamsara.com',
          image: Portfolio1,
          imagemob: Portfolio1mob,
          title: 'SUPERSAMSARA',
          text1: 'Super Samsara is training children in the Arts in Sundar Nagar. Taught by multidisciplinary artist Arjuna, Shifu Ashoka and Mr. Paulas.',
          text2: 'It is our belief that training children at an early age is most impactful for their learning process because their minds are much more open for learning. Our teaching approach has a dual focus on classical training and encouraging creativity.'
        },
      {
          domain: 'https://fivelement.in',
          image: Portfolio2,
          imagemob: Portfolio2mob,
          title: 'FIVELEMENT',
          text1:  'The universe is a balance of five elements and these five elements are interconnected.',
          text2:  'When we are pulsating with this interconnectedness our life brims with utmost joy and positivity. Work with me to help you discover this interconnectedness and help you resolve those areas in your body, mind and soul which are preventing you from living as your true expression.'
      },
      {
        domain: 'https://geetanjalipandit.com',
        image: Portfolio3,
        imagemob: Portfolio3mob,
        title: 'GEETANJALI PANDIT',
        text1:  'We are our own most valuable asset. We are the root from which the tree of our life springs. So it follows that we must continually strive to be the highest that we can be. To realize our dreams. To reach our potential.',
        text2:  'Geetanjali coaches and motivates individuals and trains teams to realize their dreams and reach their potential. She is the breakthrough coach.'
    },
    {
      domain: 'https://leodeo.co.in',
      image: Portfolio4,
      imagemob: Portfolio4mob,
      title: 'HELMUT HANUMAN',
      text1:  'Helmut is a seasoned Pakhawaj player who has spent decades training in India with old masters of the instrument. The Pakhawaj is the percussion instrument that accompanies the Dhrupad which is the most ancient core and classical form of Indian classical music. ',
      text2:  'Helmut is fondly called Hanuman in the Dhrupad circles after the Indian god. Helmut aka Hanuman has played with some of the most senior Dhrupad masters including Ustad Moinuddin Dagar, Ustad Zia Fariduddin Dagar, Pandit Vidur Mallik, Pandit Prem Kumar Mallik and Pandit Nirmalya Dey, in addition to many other Dhrupad artists. He resides in Germany and is available to accompany Dhrupad musicians in Europe or abroad. '
      },
      {
        domain: '',
        image: Portfolio5,
        imagemob: Portfolio5mob,
        title: 'KARERI ADVENTURES',
        text1:  'Kareri Adventures organizes camping and hiking trips in the Himalayan mountains.',
        text2:  'Based in Mcleod Ganj, Dharamshala Kareri will show you the mountains.'
    },
    {
      domain: 'https://mohanshyamsharma.com',
      image: Portfolio6,
      imagemob: Portfolio6mob,
      title: 'MOHANSHYAM SHARMA',
      text1:  'Mohan Shyam Sharma is one of the leading Pakhawaj players of India. He hails from bharatpur district from Rajasthan. He learnt the pakhawaj from Pandit Tota Ram Sharma of Mathura. He also had the opportunity of learning pakhawaj from Pandit Prem Vallabh of delhi.',
      text2:  'Mohan Shyam Sharma is A grade artist of All India Radio . He started his career as an accompanist with the Dagar brothers and toured with them, and with Ustad Asad Ali Khan and Zia Mohiuddin Dagar extensively in India and abroad. He is a much sought-after accompanist for all the most eminent exponents of dhrupad, vocal and instrumental indian classical music. He also accompanied darbhanga gharana exponents- Pandit Vidhur Malika and Pandit Siya Ram Tiwari and accompanied world famous musicians of other styles as well including Pandit Ravi Shankar, Pandit Hari Prasad Chaurasia, and Kishori Amonkar. He has also performed solo in abroad countries like France, Italy, Belgium, Japan , Singapore, Switzerland, Portugal, Austria, Russia, United Kingdom, U.S.A and some neighbouring countries like Bangladesh, Pakistan, and Honkong. '
    },
    {
      domain: 'https://noidadeafsociety.org',
      image: Portfolio7,
      imagemob: Portfolio7mob,
      title: 'NOIDA DEAF SOCIETY',
      text1:  'Noida Deaf society works to empower the Deaf. NDSs mission is to educate, train and place Deaf children and youth in jobs.' ,
      text2:  'NDS has grown by leaps and bounds over the past few years and it is the premiere organization for Deaf empowerment. '
    },
    {
      domain: 'https://zenmoksha.in',
      image: Portfolio8,
      imagemob: Portfolio8mob,
      title: 'ZENMOKSHA',
      text1:  'For Yoga instructor & practioneer Dimple, Yoga is a daily living. She applies her daily practice in her day to day living.She starts her every morning with Pranayama, Asanas, Meditation & Chanting. Dimple had the good fortune & blessing to learn from Ms. Nivedita Joshi ,an ardent disciple of yogacharya late Shri BKS Iyengar.She is a respected & a dedicated teacher of Iyengar school of yoga who has shown me the path to discovering yoga. Dimple also has the divine blessing of Swami Sivananda, the founder of Sivananda School of Yoga.',
      text2:  'She has combined her training from two schools of yoga. The combination of precision & alignment along with breathing & focusing inwards has resulted in Dimple becoming a capable teacher herself. She loves to teach and share her love for yoga with her adult as well as kids students! Dimple gains her knowledge from practice, practice and more practice. She is an avid reader of swami sivananda’s books on Yoga!'
    },
]
  }
} 

  render() {
    let arrPortfolio=this.state.arrPortfolio
    return (
      <div className="pagecontainer">
      <div className="pagetitle">
          <h2>PORTFOLIO</h2>         
      </div>
        {arrPortfolio.map(client => 
          (  <div className="portfoliocontainer">
            <div  className="portfolioimage">
              <a href={client.domain} target="blank"><img  className="portfolioimageimg" src={client.image} /></a> 
            </div>
            <div  className="portfolioimagemob">
              <a href={client.domain} target="blank"><img  className="portfolioimageimg" src={client.imagemob} /></a>
          </div>
          <div className="portfoliotitlemain">  
                <h3>{client.title}</h3>
            </div>
            <div className="pagetext">
              <p>{client.text1}</p>
              <p>{client.text2}</p>     
            </div>
           </div> 
          )
          )}

    </div>
    );
  }
}

export default Portfolio;