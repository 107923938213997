import React, { Component } from "react";
import { Route } from "react-router-dom";


import Helmet from "react-helmet";
import Header from "./components/header.js";
import Topnav from "./components/topnav.js";
import Home from "./components/home.js";
import About from "./components/about.js";
import Web from "./components/web.js";
import Art from "./components/art.js";
import Books from "./components/books.js";
import Portfolio from "./components/portfolio.js";
import Contact from "./components/contact.js";
import Footer from "./components/footer.js";



import TwitterImage from "./images/fb1.png";
import FacebookImage from "./images/fb1.png";
import { homedir } from "os";

/* Variable Initialization*/
const title = "Brahmaforces - Web Mobile Art Books Print";
const description = "Brahmaforces works in the realm of software and the arts. Brahma makes cutting edge mobile first websites.";
const keywords = "Mobile First websites, art, software, Arjuna, Brahmaforces";
const url = "https://brahmaforces.com";
const sitename = "Brahmaforces";
const fbappid = "";
const canonicalurl = "https://www.brahmaforces.com";
const twittersite = "@brahmaforces";
const twittercreator = "@brahmaforces";


class App extends Component {

  render() {
    return (
      <div>
        <Header />
        <Helmet>
          <title>
                { title }
          </title>
          <meta http-equiv="Content-Type" content="text/html" />
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="theme-color" content="#000000" />
          <meta
            name="description"
            content= { description } 
          />
          <meta
            name="keywords"
            content={ keywords }
          />

          <link rel="canonical" href={canonicalurl} />

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content= { title }
          />
          <meta
            property="og:description"
            content={ description }
          />
          <meta property="og:image" content={FacebookImage} />
          <meta property="og:url" content= {url} />
          <meta property="og:site_name" content={sitename} />
          <meta property="fb:app_id" content={fbappid} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={title}
          />
          <meta
            name="twitter:description"
            content={description}
          />
          <meta name="twitter:url" content={url} />
          <meta name="twitter:image" content={TwitterImage} />
          <meta name="twitter:site" content={twittersite} />
          <meta name="twitter:creator" content={twittercreator} />
        </Helmet>
        <Topnav />
        <div className="container">
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/web" component={Web} />
          <Route path="/art" component={Art} />
          <Route path="/books" component={Books} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/contact" component={Contact} />
        </div>
      
        <Footer />

      </div>
    );
  }
}
export default App;
