import React, { Component } from "react";

import ImgArt_1920 from "../images/resized/1920/13.jpg";
import ImgArt_1800 from "../images/resized/1800/13.jpg";
import ImgArt_1200 from "../images/resized/1200/13.jpg";
import ImgArt_900 from "../images/resized/900/13.jpg";
import ImgArt_600 from "../images/resized/600/13.jpg";

class Art extends Component {
  render() {
    return (
      <div className="pagecontainer">
          <div className="pagetitle">
              <h2>ART</h2>
          </div>
    
          <div  className="heroimage">
            <img className="heroimageimg" src={ImgArt_1920} srcset={`${ImgArt_600} 600w, ${ImgArt_900} 900w, ${ImgArt_1200} 1200w, ${ImgArt_1800} 1800w, ${ImgArt_1920} 1920w`}  />
        </div>

          <div className="pagetext">              
            <p>Arjuna is polymathic artist who works across arts transform. </p>
            <p>Arjuna believes that there are no limitations to what human beings can do and has followed his passions and curiosities across disciplines to pursue diverse arts. </p>
            <p>Arjuna is visual, vocal, verbal and martial artist.</p>
            <p>Or put more simply:
            Arjuna is a painter, singer, poet, writer and fighter! </p>
            <h4>PAINTING</h4>
            <p>Arjuna has never stopped painting since childhood. His father was a trained painter. </p>
            <p>Arjuna has held Art Exhibitions including</p>
            <ul>
            <li>Journery From Samsara To Nirvana</li>
            <li>Shiva and The Devi </li>
            <li>Arjuna Does The Classical Training Part I</li>
            <li>The Sikh Gurus
            Arjuna is currently working on a series of  Buddha paintings.</li>
            </ul>
            <h4>PRINTS</h4>
            <p>Prints are limited edition graphics that are printed on paper. Arjuna has created a host of prints including:</p>
            <ul>
            <li>Serigraphs/Screen Prints</li>
            <li>Etchings</li>
            <li>Digital Prints</li>
            </ul>
            <h3>GRAPHICS</h3>
            <p>The fusion of the realms of Art and Software result in graphic art. 
            Graphic art can be hung on walls or used in Websites and Books as part of the design.  	 </p>
            <h4>COMICS AND GRAPHIC NOVELS</h4>
            <p>The fusion of graphics and writing result in comics and graphic novels. 
            Brahma has created mini graphic novels </p>
            <h4>GRAPHIC DESIGN</h4>
            <p>Brahma offers a full suite of Graphic Design Services for </p>
            <ul>
            <li>
            <p>WEBSITES</p>
            <ul>
            <li>Website Design</li>
            <li>Hero Image Design</li>
            <li>Graphic Novelettes for websites</li>
            <li>Logo </li>
            </ul>
            </li>
            <li>
            <p>PRINT</p>
            <ul>
            <li>Books</li>
            <li>Catalogs</li>
            <li>Flyers</li>
            <li>Post cards</li>
            <li>Direct Mailers</li>
            <li>Business Cards</li>
            <li>Branding</li>
            </ul>
            </li>
            </ul>
          </div>
      </div>
    );
  }
}

export default Art;