import React, { Component } from "react";
import { Link } from "react-router-dom";
import Imglogo from "../images/logoimage75.png";
import Imglogotext from "../images/logotext200.jpg";
import Imgfacebook from "../images/fb1.png";
import Imgtwitter from "../images/twitter1.png";
import Imginstagram from "../images/instagram1.png";
import Imglinkedin from "../images/li1.png";

import Imghamburger from "../images/hamburger.png";

const facebook="https://www.facebook.com/brahmaforces ";
const instagram="https://www.instagram.com/brahmaforces/";
const twitter="https://www.twitter.com/brahmaforces";
const linkedin="https://www.linkedin.com/in/brahmaforces";

class Header extends Component {
  openmobilemenu(){
   document.getElementById("topnavoverlay").style.display = "Flex"; 
  }

  closemobilemenu() {
   document.getElementById("topnavoverlay").style.display = "none"; 
  }
  
  render() {
    return (
<div className="header">
<div  className="icon">
    <div onClick={this.openmobilemenu}><img width="30px" src={Imghamburger}/></div>
</div>
          <div className="logoimage"><img className="logoactualimage" src={Imglogo} alt="Logo Image" /></div>
          <div className="logotext"><img className="logoactualtext" src={Imglogotext} alt="Logo Text" /></div>
          <div className="facebook"><a href={facebook} target="_blank"><img src={Imgfacebook}  alt="Facebook" /></a></div>
          <div className="instagram"><a href={instagram} target="_blank"><img src={Imginstagram} alt="Instagram" /></a></div>
          <div className="twitter"><a href={twitter} target="_blank"><img src={Imgtwitter} alt="Twitter" /></a></div>
          <div className="linkedin"><a href={linkedin} target="_blank"><img src={Imglinkedin} alt="Linkedin" /></a></div>

  <div id="topnavoverlay">       
      <div className="topnavoverlayul">
          <div className="iconclose">
                      <button onClick={this.closemobilemenu}><i class="fa fa-close"></i></button>
          </div>
          <ul  style={{"list-style": "none", "padding-left": 0}}>
              <li onClick={this.closemobilemenu}><Link to="/">HOME</Link></li>
              <li onClick={this.closemobilemenu}><Link to="/about">ABOUT</Link></li>
              <li onClick={this.closemobilemenu}><Link to="/web">WEB</Link></li>
              <li onClick={this.closemobilemenu}><Link to="/art">ART</Link></li>
              <li onClick={this.closemobilemenu}><Link to="/books">BOOKS</Link></li>
              <li onClick={this.closemobilemenu}><Link to="/portfolio">PORTFOLIO</Link></li>
              <li onClick={this.closemobilemenu}> <a href="https://medium.com/@arjunsinghkochhar" target="_blank">BLOG</a></li>
              <li onClick={this.closemobilemenu}><Link to="/contact">CONTACT</Link></li>
          </ul>
      </div>
  </div>

</div>
    );
  }
}       

export default Header;
