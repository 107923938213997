import React, { Component } from "react";

import Imglandscape1_1920 from "../images/landscape/1920/1_1920.jpg";
import Imglandscape1_1800 from "../images/landscape/1800/1_1800.jpg";
import Imglandscape1_1200 from "../images/landscape/1200/1_1200.jpg";
import Imglandscape1_900 from "../images/landscape/900/1_900.jpg";
import Imglandscape1_600 from "../images/landscape/600/1_600.jpg";



class Books extends Component {
  render() {
    return (
      <div className="pagecontainer">
          <div className="pagetitle">
              <h2>BOOKS</h2>
          </div>
    
          <div  className="heroimage">
            <img className="heroimageimg" src={Imglandscape1_1920} srcset={`${Imglandscape1_600} 600w, ${Imglandscape1_900} 900w, ${Imglandscape1_1200} 1200w, ${Imglandscape1_1800} 1800w, ${Imglandscape1_1920} 1920w`} alt="" />
        </div>

          <div className="pagetext">
            <h4>NOTEBOOKS</h4>
            <p>Brahma is an expert book maker and in 2010 launched a brand of notebooks, Brahma Books.</p>
            <h4>PRINTED BOOKS</h4>
            <p>Brahma is adept at book design and launched its first published book in 2007 titled </p>
            <p>Arjuna Does The Classical Training Part I</p>
          </div>
      </div>
    );
  }
}

export default Books;

