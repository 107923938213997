import React, { Component } from "react";

import ImgWeb_1920 from "../images/resized/1920/4.jpg";
import ImgWeb_1800 from "../images/resized/1800/4.jpg";
import ImgWeb_1200 from "../images/resized/1200/4.jpg";
import ImgWeb_900 from "../images/resized/900/4.jpg";
import ImgWeb_600 from "../images/resized/600/4.jpg";


class Web extends Component {
  render() {
    return (
      <div className="pagecontainer">
          <div className="pagetitle">
              <h2>WEB</h2>
          </div>
    
          <div  className="heroimage">
            <img alt="Brahmaforces Web" className="heroimageimg" src={ImgWeb_1920} srcset={`${ImgWeb_600} 600w, ${ImgWeb_900} 900w, ${ImgWeb_1200} 1200w, ${ImgWeb_1800} 1800w, ${ImgWeb_1920} 1920w`} />
        </div>

          <div className="pagetext">
            <h4>BRAND POSITIONING</h4>
            <p>The mobile website is first point of contact that a lead has with a company. </p>
            <p>Most people look at websites on the mobile phone first.</p>
            <p>Most company’s send a link to their website to leads via Whatsapp or Facebook and these links must open beautifully on mobile phones.  
            </p>
            <p>The company’s mobile website must therefore project the image that the company would like to project in order to win over the client. The question then becomes what image would the company like to project? </p>
            <p>The company must be able to project an image that represents what the company actually is all about and also what its client’s would like to see if they are to hand over their business to the company.  
            </p>
            <p>This is Brand Positioning. </p>
            <p>The Brand Positioning dictates the Content, Design, Graphics, Copy and overall look and feel of the website. </p>
            <p>Brahamforces will interview the management of the company to determine how the company views itself, its vision, mission, goals and how it would like to present itself to customers. </p>
            <p>Brahma will also understand who the company’s customers are, identify the Market Segments that it caters to and the Target Market. 
            Based on theBrahmaforces will create a Brand Positioning document which will clearly articulate how the company sees itself and what self image it would like to project to the world. This document will be the blueprint that will dictate all further design and development of the mobile first website.</p>
            <h4>THE MOBILE FIRST PARADIGM</h4>
            <p>Brahma’s websites are designed for the Mobile Phone first. 
            They will work well on all devices but the primary design will be for the mobile phone, based on the assumption that most people will access a link sent to them on their mobile phone. </p>
            <p>The mobile first paradigm will set limitations on the design and copy for the website. Due to the limited real estate on the mobile phone the design will veer towards the minimal and the copy will be reduced to key sentences. </p>
            <h4>COPYWRITING</h4>
            <p>The copy writing is the first step in the development of the website. 
            The copy should be short and crisp to be most effective on the mobile phone. 
            More detailed content will be provided for those who want to read more in depth, but it will be a second priority. The most important copy will be summarized in short sentences that will stand out on a mobile screen. 
            The different sections of the copy will culminate in the formation of a sitemap. The Sitemap will articulate the links that appear on the top menu. The mobile first paradigm implies that we do not bury content in sub menus because this would require more clicks and navigation on the mobile.</p>
            <h4>GRAPHIC DESIGN</h4>
            <p>The visual impact is the first impression that the brand makes upon its leads and is most powerful.</p>
            <p>The Graphic Design involves translating the company’s Brand Positioning into a beautiful design that reflects the company’s aesthetic. If such an aesthetic does not exist then it is about creating it. </p>
            <p>Clients have the option of choosing from Design Templates that Brahma has already designed in case they prefer to save the time and cost of creating a customized design. </p>
            <h4>WEBSITE PROGRAMMING:</h4>
            <p>Brahma uses cutting edge technology in programming its websites making for an exceptionally smooth user interface and a fast loading experience on all devices.
            Brahma is primarily a software technology company and therefore Research &amp; Development in the realm of software is a key area of focus. </p>
            <p>Brahma’s clients benefit from this research as Brahma makes cutting edge freshly coded websites.</p>
            <p>Once the Design and Copy are approved, the web site is programmed, tested and then deployed on Brahma’s servers in the USA. </p>
            <h4>WEBSITE HOSTING</h4>
            <p>Brahma provides fast and reliable web hosting which includes regular backups. </p>
            <h4>DOMAIN NAME MANAGEMENT</h4>
            <p>Brahma registers domain names and renews them annually.</p>

          </div>
      </div>
    );
  }
}

export default Web;


